import { activeConfig } from 'config/clientConfig';
import { APICore } from './apiCore';
import { NewUSerJD } from 'pages/newUser/NewPatientForm';
import { NewDocJD } from 'pages/newUser/NewDoctorForm1';
import { NewAdminJD } from 'pages/newUser/NewAdminForm1';
import { NewGeneralUSerJD } from 'pages/newUser/NewGeneralUserForm';
import { NewDemoUserForm } from 'pages/newUser/NewDemoUser';

const api = new APICore();

type UpdateUserData = {
    uuid: string,
    uuid_edit: string,
    permission: number,
    email: string,
    user_name: string,
    type_user_id: number,
    group_type_user_id?: string,
    password: string,
    passwordOld: string,

    uuid_clinic: string,
    reference_id: string,
    name: string,
    last_name: string,
    birth_date: string,
    phone: string,
    emergency_phone: string,
    birth_address: string,
    address: string,
    height: number,
    Weight: number,
    gender_id: number,
    photo: string,
    about_user: string,
    unit_temperature_id: number,
    unit_weight_id: number,
    unit_height_id: number,
    LicensePricing: string,
    ReName: string,
    RePhoneNumber: string,
    ReMail: string,
    domain?: string,
    blood_type?: number,
    
    emergencyName?: string,
    emergencyCellphone?: string,
    emergencyRelationship?: string,
    group_doctor_specialties?: string,
};

type CampaignData = {
    uuid: string,
    name: string,
    description: string,
    state: number,
    city: string,
    location: string,
    initDate: string,
    endDate: string,
    domain?: string
};


type UpdateParameterData = {
    ParameterId: string,
    ParameterValue: string,
    uuid_edit: string,
};

function getUserData(params: { uuid: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserInfo/`;
    const payload = {
        uuid: params.uuid,
    }
    return api.create(`${baseUrl}`, payload);
}
function getUserClinic(params: { uuid: string }) {
    const baseUrl = '/GetUserClinic/';
    const payload = {
        uuid: params.uuid,
    }

    return api.create(`${baseUrl}`, payload);
}

function updateUser(user: UpdateUserData) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updateUser/`;
    const payload = user
    return api.create(`${baseUrl}`, payload);
};

function newUser(user: NewGeneralUSerJD) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/v2/newUser/`;
    const payload = user
    return api.create(`${baseUrl}`, payload);
};

function newPatient(user: NewUSerJD) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/v2/newPatient/`;
    const payload = user
    return api.create(`${baseUrl}`, payload);
}

function newDoctor(user: NewDocJD) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/v2/newDoctor/`;
    const payload = user
    return api.create(`${baseUrl}`, payload);
}

function newAdmin(user: NewAdminJD) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/v2/newAdmin/`;
    const payload = user
    return api.create(`${baseUrl}`, payload);
}

function newDemoUser(user: NewDemoUserForm) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/newDemoUser/`;
    const payload = user;
    return api.create(`${baseUrl}`, payload);
}

function newPatientBatch(users: NewUSerJD[]) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/batchCreateUsers/`;
    const payload = users
    return api.create(`${baseUrl}`, payload);
}

function PostUpdateParameter(Parameter: UpdateParameterData) {
    const baseUrl = '/UpdateParameter/';
    const payload = {
        uuid: Parameter.uuid_edit,
        value: Parameter.ParameterValue,
        ParameterId: Parameter.ParameterId


    }
    // console.log(payload)
    return api.create(`${baseUrl}`, payload);
}
function createUserForAdmin(user: UpdateUserData) {
    const baseUrl = `/{clientUuid}/createUserForAdmin/`;
    user.domain = activeConfig.api.DOMAIN//'sanita_remota'
    const payload = user

    return api.create(`${baseUrl}`, payload);
}

function createHealthCampaign(campaign: CampaignData) {
    const baseUrl = `${activeConfig.api.DOMAIN}/createHealthCampaign/`;
    campaign.domain = activeConfig.api.DOMAIN//'sanita_remota'
    const payload = campaign

    return api.create(`${baseUrl}`, payload);
}


function getUsers(params: { TypeUser: number }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUsers/`;
    const payload = {
        type_user_id: params.TypeUser,
    }
    return api.create(`${baseUrl}`, payload);
}

function getHolders(params: { TypeUser: number }): any {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getHolders/`;
    const payload = {
        type_user_id: params.TypeUser,
    }
    return api.create(`${baseUrl}`, payload);
}

function deleteUSer(params: { uuid: string, uuid_edit: string }): any {
    const baseUrl = `/${activeConfig.api.DOMAIN}/deleteUser/`;
    const payload = {
        uuid: params.uuid,
        uuid_edit: params.uuid_edit
    }
    return api.create(`${baseUrl}`, payload);
}


function getCampaigns(params: { uuid?: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getCampaigns/`;
    const payload = {
        uuid: params.uuid ? params.uuid : '',
        campaignStatus: ''
    }
    return api.create(`${baseUrl}`, payload);
}

function getParameters(params: { name: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getParameters/`;
    const payload = {
        name: params.name
    }
    return api.create(`${baseUrl}`, payload);
}

function getParametersDomain(params: { name: string, Audience: string, Permisology: string }) {
    const baseUrl = `${activeConfig.api.DOMAIN}/getParametersDomain/`;
    const payload = {
        name: activeConfig.api.DOMAIN,
        Audience: params.Audience,
        Permisology: params.Permisology
    }
    //  console.log(payload);
    return api.create(`${baseUrl}`, payload);
}

function getDomain(params: { name: string }) {
    const baseUrl = '/getDomain/';
    const payload = {
        name: params.name
    }
    // console.log('Clients');
    return api.create(`${baseUrl}`, payload);
}

function updateUserBlockActivate(params: { uuid: string, uuid_edit: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/updateUserBlockActivate/`;
    const payload = {
        uuid: params.uuid,
        uuid_edit: params.uuid_edit
    }
    return api.create(`${baseUrl}`, payload);
}
function updateConvertAdmin(params: { uuid: string, uuid_edit: string }) {
    const baseUrl = '/updateConvertAdmin/';
    const payload = {
        uuid: params.uuid,
        uuid_edit: params.uuid_edit
    }
    // console.log('Clients');
    return api.create(`${baseUrl}`, payload);
}

function getUserStatisticsAdmin(params: { uuid_user: string }) {
    const baseUrl = `${activeConfig.api.DOMAIN}/getUserStatisticsAdmin/`;
    const payload = {
        uuid: params.uuid_user
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserStatisticsAdminCreate(params: { uuid_user: string }) {
    const baseUrl = `${activeConfig.api.DOMAIN}/getUserStatisticsAdminCreate/`;
    const payload = {
        uuid: params.uuid_user,
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserStatisticsAdminMeasurementGeneral(params: { uuid_user: string, Period: string, status_measurement: string }) {
    const baseUrl = `${activeConfig.api.DOMAIN}/getUserStatisticsAdminMeasurementGeneral/`;
    const payload = {
        uuid: params.uuid_user,
        Period: params.Period,
        status_measurement: params.status_measurement,
    }

    return api.create(`${baseUrl}`, payload);
}
function getUserStatisticsAdminMeasurementPerMinute(params: { uuid_user: string, Period: string }) {
    const baseUrl = `${activeConfig.api.DOMAIN}/getUserStatisticsAdminMeasurementPerMinute/`;
    const payload = {
        uuid: params.uuid_user,
        Period: params.Period,
    }
    return api.create(`${baseUrl}`, payload);
}

function getUserStatisticsAdminUsersActive(params: { uuid_user: string }) {
    const baseUrl = `${activeConfig.api.DOMAIN}/getUserStatisticsAdminUsersActive/`;
    const payload = {
        uuid: params.uuid_user,
    }

    return api.create(`${baseUrl}`, payload);
}

function setUserPermission(params: { uuid: string, permit: number }) {
    const baseUrl = `${activeConfig.api.DOMAIN}/setDoctorPermission/`;
    const payload = {
        uuid: params.uuid,
        permission: params.permit
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserPermission(params: { uuid: string }) {
    const baseUrl = `${activeConfig.api.DOMAIN}/getDoctorPermissions/`;
    const payload = {
        uuid: params.uuid
    }

    return api.create(`${baseUrl}`, payload);
}
function getUserNotifications(params: { uuid: string, measurement_type: string, notification_level: string, notification_status: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserNotifications/`;
    const payload = {
        uuid: params.uuid,
        measurement_type: params.measurement_type,
        notification_level: params.notification_level,
        notification_status: params.notification_status,
    }

    return api.create(`${baseUrl}`, payload);
}

function getUserNotificationsSystems(params: { uuid: string, measurement_type: string, notification_level: string, notification_status: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserNotificationsSystems/`;
    const payload = {
        uuid: params.uuid,
        measurement_type: params.measurement_type,
        notification_level: params.notification_level,
        notification_status: params.notification_status,
    }
    return api.create(`${baseUrl}`, payload);
}

function createSystemNotification(params: {
    uuid: string,
    uuid_edit: string,
    notification_level: string,
    comment: string
}) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/createSystemNotification/`;
    const payload = {
        uuid: params.uuid,
        uuid_edit: params.uuid_edit,
        notification_level: params.notification_level,
        comment: params.comment,
    }

    return api.create(`${baseUrl}`, payload);
}


function UpdateNotificationGeneric(params: { uuid_user: string, nogId: string, statusCurrent: string, comment: string }) {
    const baseUrl = `/${activeConfig.api.DOMAIN}/UpdateNotificationGeneric/`;
    const payload = {
        uuid_edit: params.uuid_user,
        nog_id: params.nogId,
        status_measurement: params.statusCurrent,
        comment: params.comment,

    }

    return api.create(`${baseUrl}`, payload);
}

const preAssignPatientsToDoctor = async (params: {
    creatorUuid: string,
    doctorUuid: string,
    patientUuids: string[]
}) => {
    const baseUrl = `/${activeConfig.api.DOMAIN}/preAssignPatientsToDoctor/`;
    return api.create(baseUrl, params);
};


const preRemovePatientsFromDoctor = async (params: {
    creatorUuid: string,
    doctorUuid: string,
    patientUuids: string[]
}) => {
    const baseUrl = `/${activeConfig.api.DOMAIN}/preRemovePatientsFromDoctor/`;
    return api.create(baseUrl, params);
};

const getDoctorPreAssignedPatients = async (params: {
    doctorUuid: string
}) => {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getDoctorPreAssignedPatients/`;
    return api.create(baseUrl, {
        doctorUuid: params.doctorUuid
    });
};

const getDoctorAssignedPatients = async (params: {
    doctorUuid: string
}) => {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getDoctorAssignedPatients/`;
    return api.create(baseUrl, {
        doctorUuid: params.doctorUuid
    });
};

const confirmDoctorPatientsAssignment = async (params: {
    creatorUuid: string,
    doctorUuid: string,
    assignmentType: number
}) => {
    const baseUrl = `/${activeConfig.api.DOMAIN}/confirmDoctorPatientsAssignment/`;
    return api.create(baseUrl, params);
};

const removePatientsFromDoctor = async (params: {
    creatorUuid: string,
    doctorUuid: string,
    patientUuids: string[],
    removeAll: number
}) => {
    const baseUrl = `/${activeConfig.api.DOMAIN}/removePatientsFromDoctor/`;
    return api.create(baseUrl, params);
};

const getUserRoles = async (params: {
    uuid: string,
    uuid_edit: string
}) => {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserRoles/`;
    return api.create(baseUrl, params);
};

const changeUserRoles = async (params: { 
    uuid: string,
    uuid_edit: string,
    typeUserId: number,
}) => {
    const baseUrl = `/${activeConfig.api.DOMAIN}/changeUserRoles/`;
    return api.create(baseUrl, params);
};

const getDoctorSpecialtiesByClient = async (params: {
    userUuid: string
}) => {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getDoctorSpecialtiesByClient/`;
    return api.create(baseUrl, params);
};

const getUserDoctorSpecialties = async (params: {
    doctorUuid: string,
    userUuidEdit: string
}) => {
    const baseUrl = `/${activeConfig.api.DOMAIN}/getUserDoctorSpecialties/`;
    return api.create(baseUrl, params);
};

const changeDoctorSpecialty = async (params: {
    doctorUuid: string,
    userUuidEdit: string,
    doctorSpecialtyId: number,
}) => {
    const baseUrl = `/${activeConfig.api.DOMAIN}/changeDoctorSpecialty/`;
    return api.create(baseUrl, params);
};

export {
    getUserData, updateUser, getUserClinic, getHolders, deleteUSer,
    createUserForAdmin, getUserStatisticsAdmin, getUsers, getUserStatisticsAdminCreate
    , getUserStatisticsAdminMeasurementGeneral, getUserStatisticsAdminUsersActive, getUserStatisticsAdminMeasurementPerMinute
    , updateUserBlockActivate, updateConvertAdmin, setUserPermission, getUserPermission, getParameters, getParametersDomain, getDomain
    , PostUpdateParameter, createHealthCampaign, getCampaigns, getUserNotifications, getUserNotificationsSystems, createSystemNotification
    , UpdateNotificationGeneric, newPatient, newPatientBatch, preAssignPatientsToDoctor, preRemovePatientsFromDoctor, getDoctorPreAssignedPatients
    , getDoctorAssignedPatients, confirmDoctorPatientsAssignment
    , removePatientsFromDoctor, newDoctor, newAdmin, newUser, newDemoUser
    , getUserRoles, changeUserRoles, getDoctorSpecialtiesByClient
    , getUserDoctorSpecialties, changeDoctorSpecialty
};

export type { UpdateUserData, CampaignData, UpdateParameterData };