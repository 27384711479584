import React, { Suspense } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import { LayoutTypes } from 'appConstants';
import { useRedux, useUser } from 'hooks';
import { activeConfig } from 'config/clientConfig';
import FeatureFlag from 'components/FeatureFlag/FeatureFlag';

// lazy load all the views

// auth
const Login = React.lazy(() => import('pages/account/Login'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));
const ForgetPassword = React.lazy(() => import('pages/account/ForgetPassword'));
const TermsAndConditions = React.lazy(() => import('pages/account/TermsAndConditions'));
const PrivacyPolicy = React.lazy(() => import('pages/account/PrivacyPolicy'));

const Confirm2 = React.lazy(() => import('pages/account/Confirm2'));
const ForgetPassword2 = React.lazy(() => import('pages/account/ForgetPassword2'));

// dashboard
const AnalyticsDashboard = React.lazy(() => import('pages/dashboard/Analytics'));
const EcommerceDashboard = React.lazy(() => import('pages/dashboard/Ecommerce'));

// -crm
const CRMOrderList = React.lazy(() => import('pages/management/CRM/OrderList'));

// - ecommece pages
const Patient = React.lazy(() => import('pages/management/Users/Patient'));
const Doctor = React.lazy(() => import('pages/management/Users/Doctor'));
const Admin = React.lazy(() => import('pages/management/Users/Admin'));
const Params = React.lazy(() => import('pages/apps/Params/Params'));
const AlertDashboard = React.lazy(() => import('pages/apps/Alerts/'));
const sistemsAlertDashboard = React.lazy(() => import('pages/apps/SistemsAlerts/'));

// pages
const Profile = React.lazy(() => import('pages/profile'));
const CreateClient = React.lazy(() => import('pages/CreateClient'));
const NewUSer = React.lazy(() => import('pages/newUser')); // React.lazy(() => import('pages/CreateUser'));const NewUSer 
const NewPatient = React.lazy(() => import('pages/newUser/NewPatient'));
const NewDoc = React.lazy(() => import('pages/newUser/NewDoctor'));
const NewAdmin = React.lazy(() => import('pages/newUser/NewAdmin'));
const NewDemoUser = React.lazy(() => import('pages/newUser/NewDemoUser'));
const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const ErrorPageNotFoundAlt = React.lazy(() => import('pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));
const NewHealthCampaign = React.lazy(() => import('pages/healthCampaign/NewHealthCampaign'));
const HealthCampaignList = React.lazy(() => import('pages/healthCampaign/HealthCampaignList'));

const Maintenance = React.lazy(() => import('pages/other/Maintenance'));

const Landing = React.lazy(() => import('pages/landing/'));

//notification
// const AlertDashboard = React.lazy(() => import('pages/notification/Alerts'));
const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const [loggedInUser] = useUser();
    const { appSelector } = useRedux();
    const authProtectedRoutes: RouteObject[] = [];

    const { layout } = appSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    if (activeConfig.routes.analytics) {
        authProtectedRoutes.push(
            {
                path: 'analytics',
                children: [
                    {
                        path: 'activity',
                        element: <FeatureFlag name="activity">
                            <LoadComponent component={AnalyticsDashboard} />
                        </FeatureFlag>
                    },
                    {
                        path: 'summary',
                        element: <FeatureFlag name="summary">
                            <LoadComponent component={EcommerceDashboard} />
                        </FeatureFlag>
                    },
                ],
            },
        );
    }

    if (activeConfig.routes.notifications) {
        authProtectedRoutes.push(
            {
                path: 'notifications',
                children: [
                    {
                        path: 'alerts',
                        element: <FeatureFlag name="alerts">
                            <LoadComponent component={AlertDashboard} />
                        </FeatureFlag>
                    },
                    {
                        path: 'system',
                        element: <FeatureFlag name="systemAlerts">
                            <LoadComponent component={sistemsAlertDashboard} />
                        </FeatureFlag>

                    },
                ],
            },
        );
    }

    if (activeConfig.routes.account) {
        authProtectedRoutes.push(
            {
                path: 'account',
                children: [
                    {
                        path: 'profile',
                        element: <FeatureFlag name="profile">
                            <LoadComponent component={Profile} />
                        </FeatureFlag>
                    },
                ],
            },
        );
    }

    if (activeConfig.routes.users) {
        authProtectedRoutes.push(
            {
                path: 'users',
                children: [
                    {
                        path: 'patients',
                        element: <FeatureFlag name="patients">
                            <LoadComponent component={Patient} />
                        </FeatureFlag>
                    },
                    {
                        path: 'doctors',
                        element: <FeatureFlag name="doctors">
                            <LoadComponent component={Doctor} />
                        </FeatureFlag>
                    },
                    {
                        path: 'admins',
                        element: <FeatureFlag name="admins">
                            <LoadComponent component={Admin} />
                        </FeatureFlag>
                    },
                    {
                        path: 'createClient',
                        element: <FeatureFlag name="createClient">
                            <LoadComponent component={CreateClient} />
                        </FeatureFlag>
                    },
                    {
                        path: 'createUser',
                        element: <FeatureFlag name="createUser">
                            <LoadComponent component={NewUSer} />
                        </FeatureFlag>
                    },
                    {
                        path: 'createPatient',
                        element: <FeatureFlag name="createPatient">
                            <LoadComponent component={NewPatient} />
                        </FeatureFlag>
                    },
                    {
                        path: 'createDoc',
                        element: <FeatureFlag name="createDoc">
                            <LoadComponent component={NewDoc} />
                        </FeatureFlag>
                    },
                    {
                        path: 'createAdmin',
                        element: <FeatureFlag name="createAdmin">
                            <LoadComponent component={NewAdmin} />
                        </FeatureFlag>
                    },
                    {
                        path: 'createDemoUser',
                        element: <FeatureFlag name="createDemoUser">
                            <LoadComponent component={NewDemoUser} />
                        </FeatureFlag>
                    },
                    {
                        path: 'error-404-alt',
                        element: <LoadComponent component={ErrorPageNotFoundAlt} />
                    },
                ],
            },
        );
    }

    if (activeConfig.routes.campaigns) {
        authProtectedRoutes.push(
            {
                path: 'campaigns',
                children: [
                    {
                        path: 'list',
                        element: <FeatureFlag name="campaignList">
                            <LoadComponent component={HealthCampaignList} />
                        </FeatureFlag>
                    },
                    {
                        path: 'new-campaign',
                        element: <FeatureFlag name="createCampaign">
                            <LoadComponent component={NewHealthCampaign} />
                        </FeatureFlag>
                    }
                ],
            }
        );
    }

    return useRoutes([
        { path: '/', element: <Root /> },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'forget-password', element: <LoadComponent component={ForgetPassword} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
                        { path: 'forget-password2', element: <LoadComponent component={ForgetPassword2} /> },
                        {
                            path: 'terms', element: <FeatureFlag name="termsAndConditions">
                                <LoadComponent component={TermsAndConditions} />
                            </FeatureFlag>
                        },
                        {
                            path: 'privacy', element: <FeatureFlag name="termsAndConditions">
                                <LoadComponent component={PrivacyPolicy} />
                            </FeatureFlag>
                        },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />
                },
                {
                    path: 'landing',
                    element: <LoadComponent component={Landing} />
                },
            ],
        },

        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={Layout} />,
            children: authProtectedRoutes,
        },

        {
            // auth protected routes
            path: '/',
            element: <PrivateRoute roles={'Admin'} component={Layout} />,
            children: [
                {
                    path: 'gadgets',
                    children: [
                        {
                            path: 'list',
                            element: <LoadComponent component={CRMOrderList} />
                        },
                    ],
                },

                ...(loggedInUser?.type_user_id === 5 ? [
                    {
                        path: 'params',
                        children: [
                            {
                                path: 'general',
                                element: <LoadComponent component={Params} />
                            },
                        ],
                    },
                ] : []),

            ],
        },
    ]);
};

export { AllRoutes };
